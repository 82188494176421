import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = { variant: String };
    connect() {
        // Create a spinner image html element
        let div = document.createElement('div');
        let fillColor = this.variantValue == 'outline' ? '#6464E8' : '#ffffff';

        div.innerHTML =
            '<svg class="-ml-1 mr-1 h-5 w-5" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="16px" height="16px" viewBox="0 0 128 128" xml:space="preserve"><g><path d="M64 9.75A54.25 54.25 0 0 0 9.75 64H0a64 64 0 0 1 128 0h-9.75A54.25 54.25 0 0 0 64 9.75z" fill="' +
            fillColor +
            '"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1800ms" repeatCount="indefinite"></animateTransform></g></svg>';
        let spinner = div.firstChild;

        let buttons = this.element.querySelectorAll("input[type='submit'], button[type='submit']");

        // Replace all buttons (within this.element) svgs with the spinner
        this.element.addEventListener('submit', (event) => {
            buttons.forEach((button) => {
                let svgs = button.querySelectorAll('svg');
                if (svgs.length > 0) {
                    let svg = svgs[0];
                    svg.parentNode.replaceChild(spinner, svg);
                }
            });
        });
    }
}
