import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
    connect() {
        this.element.addEventListener('turbo:submit-start', this.startAddItem.bind(this));
        this.element.addEventListener('turbo:submit-end', this.endAddItem.bind(this));
    }

    getSkeleton() {
        return document.getElementById('topic-skeleton');
    }

    getEmptyComponent() {
        return document.getElementById('topic-empty-component');
    }

    startAddItem(event) {
        const skeleton = this.getSkeleton();
        const emptyComponent = this.getEmptyComponent();

        // Move the skeleton to the top of the list.
        skeleton.parentNode.insertBefore(skeleton, skeleton.parentNode.firstChild);

        // Show the skeleton and make the button disabled
        skeleton.classList.remove('hidden');
        this.element.disabled = true;

        // Hide the empty component
        if (emptyComponent) emptyComponent.classList.add('hidden');
    }

    endAddItem(event) {
        const skeleton = this.getSkeleton();
        const emptyComponent = this.getEmptyComponent();

        // Hide the skeleton element and make the button enabled
        skeleton.classList.add('hidden');
        this.element.disabled = false;

        if (event.detail.success && emptyComponent) {
            // There are recommendations in the list, so hide empty
            emptyComponent.classList.add('hidden');
        } else if (emptyComponent) {
            emptyComponent.classList.remove('hidden');
        }
    }
}
