import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';
export default class extends Controller {
    static targets = ['profileDropdown', 'profileButton', 'backdrop', 'mobileMenu', 'openMenuButton'];
    static values = { trigger: String };

    connect() {
        this.profileButtonTarget.addEventListener('click', this.clickProfileDropdown.bind(this));
        window.addEventListener('click', this.closeProfileDropdown.bind(this));
    }

    clickToggleMenu(event) {
        if (this.mobileMenuTarget.classList.contains('hidden')) {
            enter(this.backdropTarget);
            enter(this.mobileMenuTarget);
        } else {
            Promise.all([leave(this.backdropTarget), leave(this.mobileMenuTarget)]);
        }
    }

    clickProfileDropdown(event) {
        event.preventDefault();

        if (this.profileDropdownTarget.classList.contains('hidden')) {
            enter(this.profileDropdownTarget);
        } else {
            leave(this.profileDropdownTarget);
        }
    }

    closeProfileDropdown(event) {
        // Only close the dropdown if the click was outside of the dropdown
        if (this.profileButtonTarget === event.target || this.profileButtonTarget.contains(event.target)) return;

        leave(this.profileDropdownTarget);
    }
}
