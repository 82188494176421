import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['inputContainer', 'input', 'radio'];

    useInput() {
        // Enable the input
        this.inputTarget.disabled = false;
        this.inputTarget.focus();

        // Remove all checked classes from radio buttons
        this._removeRadioStyles();

        // Unselect all the radio buttons
        this.radioTargets.forEach((radio) => {
            radio.checked = false;
        });
    }

    useRadio(event) {
        // Disable the input
        this.inputTarget.disabled = true;

        // Remove all checked classes from radio buttons
        this._removeRadioStyles();

        // Add the checked class to the clicked radio button
        event.currentTarget.parentNode.classList.add('bg-hintsay-50', 'border-hintsay-200', 'z-10');

        let selectedSpans = event.currentTarget.nextElementSibling.querySelectorAll('span');

        selectedSpans[0].classList.remove('text-gray-800');
        selectedSpans[0].classList.add('text-hintsay-700');
        selectedSpans[1].classList.add('text-hintsay-600');
    }

    _removeRadioStyles() {
        // Remove all checked classes from radio buttons
        this.radioTargets.forEach((radio) => {
            radio.parentNode.classList.remove('bg-hintsay-50', 'border-hintsay-200', 'z-10');
            radio.parentNode.classList.add('border-gray-200');

            let selectedSpans = radio.nextElementSibling.querySelectorAll('span');

            selectedSpans[0].classList.remove('text-hintsay-700');
            selectedSpans[0].classList.add('text-gray-800');
            selectedSpans[1].classList.remove('text-hintsay-600');
            selectedSpans[1].classList.add('text-gray-600');
        });
    }
}
