import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['button', 'menu'];

    connect() {
        this.buttonTarget.addEventListener('click', () => {
            this.menuTarget.classList.toggle('hidden');
        });
    }
}
