import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
export default class extends Controller {
    static targets = ['select'];

    select(evt) {
        let select = evt.target;
        let selectedOption = select.options[select.selectedIndex];
        Turbo.visit(selectedOption.value);
    }
}
