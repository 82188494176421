import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["area", "editorStatus", "message", "loader"];

    __saveTimeout = null;

    connect() {
        this.areaTarget.addEventListener("input", this.onDelayedInput.bind(this));
        this.setGrowArea();
    }

    onDelayedInput() {
        clearTimeout(this.__saveTimeout);
        this.__saveTimeout = window.setTimeout(() => {
            this.setLoading("Saving post...");
            this._requestSave();
        }, 1000);
    }

    setLoading(message) {
        this.messageTarget.innerHTML = message;

        if (message) this.editorStatusTarget.classList.remove("hidden");
        else this.editorStatusTarget.classList.add("hidden");
    }

    setDisabled(disabled) {
        this.areaTarget.disabled = disabled;
    }

    setGrowArea() {
        let element = this.areaTarget;
        element.style.boxSizing = "border-box";
        var offset = element.offsetHeight - element.clientHeight;
        element.style.height = element.scrollHeight + offset + "px";
        element.addEventListener("input", function (event) {
            var scrollLeft =
                window.pageXOffset ||
                (document.documentElement || document.body.parentNode || document.body).scrollLeft;

            var scrollTop =
                window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

            event.target.style.height = "auto";
            event.target.style.height = event.target.scrollHeight + offset + "px";

            window.scrollTo(scrollLeft, scrollTop);
        });
    }

    _requestSave() {
        fetch(this.element.action, {
            method: this.element.method,
            body: new FormData(this.element),
        }).finally(() => {
            this.setLoading(false);
        });
    }
}
