import Quill from "quill";

const Inline = Quill.import("blots/inline");
class HashTagBlot extends Inline {
    static blotName = "hashtag";
    static className = "ql-hashtag";
    static tagName = "a";

    static create(value) {
        const node = super.create(value);
        node.setAttribute("href", "#");
        return node;
    }
}

Quill.register(HashTagBlot);
