import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['thumbsUp', 'thumbsDown'];

    connect() {
        if (this.hasThumbsUpTarget && this.hasThumbsDownTarget) {
            this.thumbsUpTarget.addEventListener('click', this.check.bind(this));
            this.thumbsDownTarget.addEventListener('click', this.check.bind(this));
        }
    }

    check() {
        if (this.element.querySelector('button') === null) {
            this.element.requestSubmit();
        }
    }
}
