import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        content: String,
    };

    clickGeneratePostLink(event) {
        event.preventDefault();

        let generatePostFormField = document.getElementById('post_query_context');
        generatePostFormField.value = this.contentValue;
        generatePostFormField.focus();
    }
}
