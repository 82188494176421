import { Controller } from "@hotwired/stimulus";

import { navigator } from "@hotwired/turbo";

export default class extends Controller {
    connect() {
        this.showProgressBar();
        let hideBar = this.hideProgressBar;
        fetch(this.element.action, {
            method: this.element.method,
            headers: {
                Accept: "text/vnd.turbo-stream.html",
            },
            body: new FormData(this.element),
        }).then((response) => {
            response.text().then((text) => {
                window.Turbo.renderStreamMessage(text);
                hideBar();
            });
        });
    }

    showProgressBar() {
        navigator.delegate.adapter.progressBar.setValue(0);
        navigator.delegate.adapter.progressBar.show();
    }

    hideProgressBar() {
        navigator.delegate.adapter.progressBar.setValue(1);
        navigator.delegate.adapter.progressBar.hide();
    }
}
