import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";

export default class extends Controller {
    static targets = ["canvas", "data"];

    connect() {
        const chart = this.canvasTarget.getContext("2d");

        const data = this.getData();

        let minYValue = Math.max(0, Math.min(...data));
        let maxYValue = Math.max(...data);
        let yPadding = (maxYValue - minYValue) * 0.2;

        const skipped = (ctx, value) => (ctx.p0.skip || ctx.p1.skip ? value : undefined);
        new Chart(chart, {
            type: "line",
            data: {
                labels: [...Array(data.length).keys()],
                datasets: [
                    {
                        // backgroundColor: gradient,
                        pointBackgroundColor: "#ff5ecc",
                        data: data,
                        tension: 0.2,
                    },
                ],
            },
            options: {
                spanGaps: true,
                segment: {
                    borderColor: (ctx) => skipped(ctx, "rgb(100, 100, 232,0.5)"),
                    borderDash: (ctx) => skipped(ctx, [6, 6]),
                },
                responsive: true,
                layout: { padding: 0 },
                maintainAspectRatio: false,
                datasets: {
                    line: {
                        backgroundColor: this.getGradientBackground(chart),
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },

                hover: { mode: null },
                elements: {
                    line: {
                        fill: true,
                        borderColor: "#6464E8",
                        borderWidth: 2,
                        tension: 0.4,
                    },
                    point: {
                        radius: (new Array(data.length).join("0") + "3").split("").map(parseFloat),
                        borderWidth: 2,
                        borderColor: "#ffbdea",
                    },
                },
                scales: {
                    y: {
                        display: false,
                        min: minYValue - yPadding,
                        max: maxYValue + yPadding,
                    },
                    x: {
                        display: false,
                    },
                },
            },
        });

        // new Chart(chart, {
        //     type: "line",
        //     data: {
        //         labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        //         datasets: [
        //             {
        //                 data: [435, 321, 532, 801, 1231, 1098, 732, 321, 451, 482, 513, 397],
        //             },
        //         ],
        //     },
        //     options: {
        //         responsive: false,
        //         legend: {
        //             display: false,
        //         },
        //         elements: {
        //             line: {
        //                 borderColor: "#000000",
        //                 borderWidth: 1,
        //             },
        //             point: {
        //                 radius: 0,
        //             },
        //         },
        //         tooltips: {
        //             enabled: false,
        //         },
        //         scales: {
        //             yAxes: [
        //                 {
        //                     display: false,
        //                 },
        //             ],
        //             xAxes: [
        //                 {
        //                     display: false,
        //                 },
        //             ],
        //         },
        //     },
        // });
    }

    // Returns the gradient background for the chart.
    getGradientBackground(chart) {
        let gradient = chart.createLinearGradient(0, 0, 0, 30);
        gradient.addColorStop(0, "#A9A9F2");
        gradient.addColorStop(1, "#FFFFFF");
        return gradient;
    }

    getData() {
        // parse the json data from the dataTarget in the data-json attribute
        return JSON.parse(this.dataTarget.dataset.json).data;
    }
}
