import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";

export default class extends Controller {
    static targets = ["canvas", "data"];

    __data = null;
    __labels = null;
    __values = null;
    __cumulative = null;

    connect() {
        const chart = this.canvasTarget.getContext("2d");

        // Sets up the data/labels/values/cumulative arrays.
        this.getData();

        // Used to show the day of the week.
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        // Helper to style gap segments in the line chart.
        const skipped = (ctx, value) => (ctx.p0.skip || ctx.p1.skip ? value : undefined);

        new Chart(chart, {
            type: "line",
            data: {
                labels: this.__labels,
                datasets: [
                    {
                        pointBackgroundColor: "#6464E8",
                        data: this.__values,
                        tension: 0.2,
                    },
                ],
            },
            options: {
                spanGaps: true,
                segment: {
                    borderColor: (ctx) => skipped(ctx, "rgb(100, 100, 232,0.5)"),
                    borderDash: (ctx) => skipped(ctx, [6, 6]),
                },
                responsive: true,
                layout: { padding: 0 },
                maintainAspectRatio: false,
                datasets: {
                    line: {
                        backgroundColor: this.getGradientBackground(chart),
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            // Creates a tooltip title like "Mon 1 Jan"
                            title: function (context) {
                                const label = context[0].label;
                                const date = new Date(label);

                                return `${days[date.getDay()]} ${date.getDate()} ${date.toLocaleString("en-US", {
                                    month: "short",
                                })}`;
                            },
                        },
                    },
                },
                elements: {
                    line: {
                        fill: true,
                        borderColor: "#6464E8",
                        borderWidth: 2,
                        tension: 0.4,
                    },
                },
                interaction: {
                    intersect: false,
                    axis: "x",
                },
                scales: {
                    y: {
                        position: "right",
                        ticks: {
                            count: 4,
                            callback: function (value, index, ticks) {
                                return Math.round(value);
                            },
                        },
                    },
                    x: {
                        ticks: {
                            callback: function (value, index, ticks) {
                                const label = this.getLabelForValue(value);

                                const date = new Date(label);

                                if (ticks.length > 8) {
                                    return `${date.getDate()}/${date.getMonth() + 1}`;
                                }

                                return `${days[date.getDay()]} ${date.getDate()}/${date.getMonth() + 1}`;
                            },
                        },
                    },
                },
            },
        });
    }

    // Returns the gradient background for the chart.
    getGradientBackground(chart) {
        let gradient = chart.createLinearGradient(0, 0, 0, 140);
        gradient.addColorStop(0, "#A9A9F2");
        gradient.addColorStop(1, "rgba(255,255,255,0)");
        return gradient;
    }

    // Gets the data from the data-target element.
    // It's a JSON string, so we need to parse it.
    getData() {
        this.__data = JSON.parse(this.dataTarget.dataset.json).data;
        this.__labels = this.getLabels();
        this.__values = this.getValues();
        this.__cumulative = this.getCumulative();
    }

    // Gets an array of labels from the data.
    getLabels() {
        return Object.keys(this.__data);
    }

    // Gets an array of values from the data.
    getValues() {
        return Object.values(this.__data);
    }

    // Creates an array of cumulative values.
    getCumulative() {
        const cumulativeSum = (
            (sum) => (value) =>
                (sum += value)
        )(0);

        return this.__values.map(cumulativeSum);
    }
}
