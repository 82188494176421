import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  connect() {
    console.log("here");
    const userTimeZoneField = document.querySelector("input[name='time_zone']");

    const userTimeZoneNow = document.querySelector("input[name='time_zone_now']");
    
    // Get the current date in UTC
    const currentDate = new Date();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    // Set the hidden field value to userTimeZone
    userTimeZoneField.value = userTimeZone;
    userTimeZoneNow.value = userTimeZone;

    // Set the current date to UTC+0
    currentDate.setUTCHours(currentDate.getUTCHours(), 0, 0, 0);

    // Calculate the start of the next hour
    const startOfNextHour = new Date(currentDate);
    startOfNextHour.setUTCHours(startOfNextHour.getUTCHours() + 1);

    flatpickr(".post_schedule", {
      enableTime: true,
      dateFormat: "F j,Y h:i K",
      defaultDate: startOfNextHour,
      minDate: currentDate,
    });
  }
}