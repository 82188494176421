import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";
export default class extends Controller {
    static targets = ["backdrop", "content", "backdropContainer"];

    connect() {
        this.open();
        this.element.addEventListener("click", (e) => this.backdropClick(e));
    }

    open() {
        enter(this.backdropTarget);
        enter(this.contentTarget);
        const body = document.querySelector("body");
        body.style.overflow = "hidden";
    }

    close() {
        leave(this.backdropTarget);
        leave(this.contentTarget).then(() => {
            this.element.remove();
        });

        const body = document.querySelector("body");
        body.style.overflow = "auto";
    }

    error() {
        this.close();
    }

    backdropClick(event) {
        (event.target === this.backdropTarget || event.target === this.backdropContainerTarget) && this.close(event);
    }
}
