// From https://github.com/quilljs/quill/issues/109
export function highlightHashtags(quill) {
    const hashtagPattern = /#[a-z0-9_]+/;

    quill.keyboard.addBinding(getTypingHighlighterBinding(hashtagPattern, " ", "hashtag", quill));
    quill.keyboard.bindings[13].unshift(getTypingHighlighterBinding(hashtagPattern, 13, "hashtag", quill));
    pasteHighlighter(hashtagPattern, "hashtag", quill);
}

export function highlightLinks(quill) {
    const linkPattern = /https?:\/\/[^\s]+$/;

    quill.keyboard.addBinding(getTypingHighlighterBinding(linkPattern, " ", "link", quill));
    quill.keyboard.bindings[13].unshift(getTypingHighlighterBinding(linkPattern, 13, "link", quill));
    pasteHighlighter(linkPattern, "link", quill);
}

function getTypingHighlighterBinding(regexPattern, key, blotType, quill) {
    return {
        collapsed: true,
        key: key,
        prefix: regexPattern,
        handler: (range, context) => {
            const prefixMatch = context.prefix.match(regexPattern);
            if (prefixMatch === null) return true;
            const prefixLength = prefixMatch[0].length;
            const prefixStart = range.index - prefixLength;
            const matchedText = quill.getText(prefixStart, prefixLength);
            quill.formatText(prefixStart, prefixLength, { [blotType]: matchedText }, "user");
            return true;
        },
    };
}

function pasteHighlighter(regexPattern, blotType, quill) {
    quill.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
        const combinedRegexp = new RegExp(regexPattern, "g");

        const ops = [];
        const str = node.data;
        let lastMatchEndIndex = 0;
        let match = combinedRegexp.exec(str);
        while (match !== null) {
            if (match.index > lastMatchEndIndex) {
                ops.push({ insert: str.slice(lastMatchEndIndex, match.index) });
            }

            ops.push({ insert: match[0], attributes: { [blotType]: match[0] } });
            lastMatchEndIndex = match.index + match[0].length;
            match = combinedRegexp.exec(str);
        }

        if (lastMatchEndIndex < str.length) {
            ops.push({ insert: str.slice(lastMatchEndIndex) });
        }

        delta.ops = ops;
        return delta;
    });
}
