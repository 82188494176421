import { Controller } from "@hotwired/stimulus";
import Quill from "quill";
import "./blots";
import { highlightHashtags, highlightLinks } from "./highlighters";

export default class extends Controller {
    static targets = ["container", "hidden", "editorStatus", "state", "message", "loader"];

    __saveTimeout = null;
    __quill = null;

    connect() {
        this.quillInit();
        highlightHashtags(this.__quill);
        highlightLinks(this.__quill);
        this.__quill.focus();
    }

    onDelayedInput() {
        clearTimeout(this.__saveTimeout);
        this.__saveTimeout = window.setTimeout(this._savePost.bind(this), 2000);
    }

    _savePost() {
        this.setLoading("Saving post...");

        // Update the hidden form inputs with body and editor state.
        this.hiddenTarget.value = this.__quill.getText().replace(/\n/, "\n\n"); //this.__quill.root.innerHTML;
        this.stateTarget.value = JSON.stringify(this.__quill.getContents());

        // Save the form
        this._requestSave();
    }

    _requestSave() {
        this.element.requestSubmit();
    }

    setLoading(message) {
        this.messageTarget.innerHTML = message;

        if (message) this.editorStatusTarget.classList.remove("invisible");
        else this.editorStatusTarget.classList.add("invisible");
    }

    quillInit() {
        this.__quill = new Quill(this.containerTarget, this.quillOption);

        this._updateFromState();

        this.containerTarget.classList.remove("invisible");

        // Remove all formatting from pasted text
        this.__quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
            var plaintext = node.innerText + "\n";
            var Delta = Quill.import("delta");
            return new Delta().insert(plaintext);
        });

        // Add a custom keyboard shortcut for saving
        this.__quill.keyboard.addBinding({
            key: "s",
            shortKey: true,
            handler: this._savePost.bind(this),
        });

        // Disable bold
        this.__quill.keyboard.addBinding({
            key: "b",
            shortKey: true,
            handler: () => false,
        });

        // While we type, copy the text to our hidden form field so it can be saved.
        this.__quill.on("text-change", this.onDelayedInput.bind(this));

        // listen for form submit success turbo end
        this.element.addEventListener("turbo:submit-end", (event) => {
            this.setLoading();
        });

        document.addEventListener("turbo:submit-start", (event) => {
            if (event.target.id == "generate-post-form") {
                this.setLoading("Generating post...");
            }
        });

        document.addEventListener("turbo:submit-end", (event) => {
            if (event.target.id == "generate-post-form") {
                this.setLoading();
                if (this.stateTarget.value) {
                    this.__quill.setContents(JSON.parse(this.stateTarget.value));
                }
            }
        });
    }

    _updateFromState() {
        if (this.stateTarget.value.length) {
            this.__quill.setContents(JSON.parse(this.stateTarget.value));
        }
    }

    // Quill configuration options
    get quillOption() {
        return {
            readOnly: false,
            placeholder: "Start typing...",
            modules: {
                toolbar: [],
                history: {
                    delay: 500,
                    maxStack: 500,
                },
            },
            formats: ["hashtag", "link"],
        };
    }
}
