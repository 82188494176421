import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    // Disables all form buttons on the page, making sure
    // the user won't submit another "Generate post".
    disableButtons() {
        document.querySelectorAll("input[type='submit'], button[type='submit']").forEach((button) => {
            button.disabled = true;
        });
    }
}
